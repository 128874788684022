
import { defineComponent, ref } from "vue";
import { ElMessage } from "element-plus";
import authHandler from "@/utils/auth-handler";
import { useRouter } from "vue-router";
import { useStore } from "@/store/index";

export default defineComponent({
  name: "HeaderInfo",
  setup() {
    const router = useRouter();
    const { state } = useStore();

    const handleCommand = (command: string) => {
      switch (command) {
        case "userinfo":
          {
            router.push("/admin-info");
          }
          break;
        case "logout":
          {
            authHandler.logOut();
            ElMessage({
              type: "success",
              message: "退出成功"
            });
            router.push("/login");
          }
          break;
      }
    };
    return {
      admin: ref(state.authData.admin),
      handleCommand
    };
  }
});
